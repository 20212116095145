import { useEffect, useState } from "react";
import { Card, Pagination } from "../components";

export const MediaGrid = ({
  user,
  mediaList,
  mediaType,
  dataPerPage,
  useTMDB = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const changePageNo = (number) => setCurrentPage(number);
  const lastIndex = currentPage * dataPerPage;
  const firstIndex = lastIndex - dataPerPage;
  const currentData = mediaList?.slice(firstIndex, lastIndex);

  useEffect(() => {
    setCurrentPage(1);
  }, [mediaList]);

  return (
    <main>
      <section className="max-w-7xl mx-auto py-7">
        <div className="flex justify-start flex-wrap other:justify-evenly">
          {currentData &&
            currentData.map((media) => (
              <Card
                key={media.media_id || media.id}
                user={user}
                media={media}
                mediaType={mediaType}
                useTMDB={useTMDB}
              />
            ))}
        </div>
        <div className="flex w-full-screen justify-center">
          <Pagination
            changePageNo={changePageNo}
            data={mediaList?.length}
            dataPerPage={dataPerPage}
            currentPage={currentPage}
          />
        </div>
      </section>
    </main>
  );
};
