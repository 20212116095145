import { Link } from "react-router-dom";

export const Admin = ({ user }) => {
  const navigation = [
    { key: 1, path: "/admin/locations", text: "Manage Locations" },
    { key: 2, path: "/admin/users", text: "Manage Users" },
  ];

  return (
    <main>
      <section className="flex flex-grow justify-around flex-wrap py-5">
        <div className="text-gray-700 text-lg dark:text-white">
          <h4 className="min-w-full text-4xl font-bold my-3 text-center lg:text-left">
            Administration
          </h4>
          <ul className="mt-6">
            {navigation.map((item) => (
              <li key={item.key}>
                <div className="flex flex-grow p-2">
                  <Link to={item.path}>
                    <h2 className="mt-2 text-2m font-bold tracking-tight text-gray-900 dark:text-white">
                      {item.text}
                    </h2>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </main>
  );
};
