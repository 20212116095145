import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "../components";

export const AdminLocations = ({ user }) => {
  const [locations, setLocations] = useState();
  const [addLocation, setAddLocation] = useState(false);
  const [locationName, setLocationName] = useState();
  const [description, setDescription] = useState();
  const [slots, setSlots] = useState();
  const [seed, setSeed] = useState(1);

  const url = process.env.REACT_APP_API_URL + `locations`;
  const locUrl = process.env.REACT_APP_API_URL + `location`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setLocations(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  const handleAddLocation = (event) => {
    async function postResults(location) {
      await axios.post(locUrl, location, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      setSeed(Math.random());
    }
    if (!addLocation) {
      setAddLocation(!addLocation);
    } else {
      const location = {
        location: locationName,
        description: description,
        slots: slots,
      };
      postResults(location);
      setAddLocation(!addLocation);
    }
  };

  const onChangeLocation = (e) => {
    const data = e.target.value;
    setLocationName(data);
  };

  const onChangeDescription = (e) => {
    const data = e.target.value;
    setDescription(data);
  };

  const onChangeSlots = (e) => {
    const data = e.target.value;
    setSlots(data);
  };

  return (
    <main>
      <section className="flex flex-grow justify-around flex-wrap py-5">
        <div className="flex flex-col">
          <table className="table-auto shadow-sm overflow-hidden my-8">
            <thead>
              <tr>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Location
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Description
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Slots
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left" />
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
              {locations &&
                locations.map((location) => (
                  <tr key={location.location}>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {location.location}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {location.description}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {location.slots}
                    </td>
                    <td>
                      <Button>Update</Button>
                      <Button range="from-red-500 via-red-600 to-red-700">
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Button onClick={() => handleAddLocation()}>
            {addLocation ? "Submit" : "Add"}
          </Button>
          {addLocation && (
            <div className="tp-3 text-slate-500 dark:text-slate-400">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline"
                id="location"
                type="text"
                placeholder="Location"
                onChange={onChangeLocation}
              />
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline"
                id="description"
                type="text"
                placeholder="Description"
                onChange={onChangeDescription}
              />
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline"
                id="slots"
                type="text"
                placeholder="Slots"
                onChange={onChangeSlots}
              />
            </div>
          )}
        </div>
      </section>
    </main>
  );
};
