import { Routes, Route, Navigate } from "react-router-dom";
import {
  MediaList,
  Catalog,
  MediaDetail,
  PageNotFound,
  PersonDetail,
  Login,
  SearchResults,
} from "../pages";
import { Admin, AdminLocations, AdminUsers } from "../pages";
import AuthService from "../adapters/Auth.Service";

export const AllRoutes = ({ user, setUser, mediaTypeId, mediaListSeed }) => {
  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  const AdminRoute = ({ user, children }) => {
    if (!user.isAdmin) {
      AuthService.logout();
      return <Navigate to="/login" replace />;
    }

    return children;
  };

  return (
    <div className="dark:bg-darkbg">
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoute user={user}>
              <MediaList
                user={user}
                title="Random"
                apiPath="media/list/random"
                queryTerm={`?mediaTypeId=${mediaTypeId}`}
                mediaListSeed={mediaListSeed}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media/nowplaying"
          element={
            <ProtectedRoute user={user}>
              <MediaList
                user={user}
                title="Recent"
                apiPath="media/list/now_playing"
                queryTerm={`?mediaTypeId=${mediaTypeId}`}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media/popular"
          element={
            <ProtectedRoute user={user}>
              <MediaList
                user={user}
                title="Home"
                apiPath="media/list/popular"
                queryTerm={`?mediaTypeId=${mediaTypeId}`}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media/toprated"
          element={
            <ProtectedRoute user={user}>
              <MediaList
                user={user}
                title="Home"
                apiPath="media/list/top_rated"
                queryTerm={`?mediaTypeId=${mediaTypeId}`}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media/catalogs"
          element={
            <ProtectedRoute user={user}>
              <Catalog
                user={user}
                title="Catalogs"
                apiPath="media/list/catalog"
                mediaTypeId={mediaTypeId}
                mediaListSeed={mediaListSeed}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media/search/:searchString"
          element={
            <ProtectedRoute user={user}>
              <SearchResults
                user={user}
                title="Search"
                mediaTypeId={mediaTypeId}
                search={true}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="media/:media_type_id/:media_id"
          element={
            <ProtectedRoute user={user}>
              <MediaDetail user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="person/:person_id"
          element={
            <ProtectedRoute user={user}>
              <PersonDetail user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          title="Login"
          element={<Login user={user} setUser={setUser} />}
        />

        <Route
          path="/admin"
          element={
            <AdminRoute user={user}>
              <Admin user={user} />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/locations"
          element={
            <AdminRoute user={user}>
              <AdminLocations user={user} />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminRoute user={user}>
              <AdminUsers user={user} />
            </AdminRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};
