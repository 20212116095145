import axios from "axios";
import { useEffect, useState } from "react";
import { SideMediaItem } from "./SideMediaItem";

export const SideMediaList = ({ user, person_id }) => {
  const [mediaList, setMediaList] = useState();
  const [seed, setSeed] = useState(1);

  const url = process.env.REACT_APP_API_URL + `person/medias/${person_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setMediaList(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  return (
    <div className="flex flex-nowrap max-w-full sm:max-h-full xl:flex-col w-screen xl:w-48 xl:max-h-screen overscroll-contain overflow-auto snap-center scroll-smooth xl:scroll-auto">
      {mediaList &&
        mediaList.map((media) => (
          <SideMediaItem user={user} key={media.media_id} media={media} />
        ))}
    </div>
  );
};
