import axios from "axios";
import { useEffect, useState } from "react";

export const Genres = ({ user, media_type_id, media_id }) => {
  const [genres, setGenres] = useState();

  const url =
    process.env.REACT_APP_API_URL + `media/genres/${media_type_id}/${media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setGenres(data);
    }
    if (media_id) {
      fetchResults();
    }
  }, [url, user.accessToken, media_id]);

  return (
    <div>
      {genres && (
        <div className="my-4 flex flex-wrap gap-2">
          {genres.map((genre) => (
            <span
              className="mr-2 border border-gray-200 rounded dark:border-gray-600 p-2 text-gray-700 dark:text-white"
              key={genre.genre_id}
            >
              {genre.genre}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
