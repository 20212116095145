import axios from "axios";
import { useEffect, useState } from "react";

export const Locations = ({
  user,
  locationId,
  setLocationId,
  editSlot,
  parentSetSeed,
}) => {
  const [locations, setLocations] = useState();
  const [seed, setSeed] = useState(1);

  const url = process.env.REACT_APP_API_URL + `locations`;

  const handleChange = (event) => {
    setLocationId(event.target.value);
    setSeed(Math.random());
    //parentSetSeed(Math.random());
  };

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setLocations(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  return (
    <main>
      {locations && (
        <form class="max-w-sm mx-auto">
          <label
            for="locations"
            className="block text-sm font-medium text-gray-900 dark:text-white"
          >
            Select a location
          </label>
          <select
            disabled={!editSlot}
            id="locations"
            onChange={handleChange}
            value={locationId}
            className="block w-3/4 p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="-1">N/A</option>
            {locations.map((location) => (
              <option key={location.location_id} value={location.location_id}>
                {location.location}
              </option>
            ))}
          </select>
        </form>
      )}
    </main>
  );
};
