import axios from "axios";
import { useEffect, useState } from "react";
import { Category } from ".";

export const Categories = ({ user, media_type_id, media_id }) => {
  const [categories, setCategories] = useState();
  const [seed, setSeed] = useState(1);

  const url =
    process.env.REACT_APP_API_URL +
    `media/categories/${media_type_id}/${media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setCategories(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  return (
    <div className="flex flex-col">
      {categories ? (
        <p className="my-4 flex flex-wrap gap-2">
          {categories.map((category) => (
            <Category
              user={user}
              media_type_id={category.media_type_id}
              media_id={category.media_id}
              category_id={category.category_id}
              parentSetSeed={setSeed}
            />
          ))}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};
