import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

export const MiniCard = ({ user, media_type_id, media_id, show_image }) => {
  const [media, setMedia] = useState();
  const [image, setImage] = useState();

  const url =
    process.env.REACT_APP_API_URL +
    `media/details/${media_type_id}/${media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data[0];
      setMedia(data);
    }
    fetchResults();
  }, [url, user.accessToken]);

  useEffect(() => {
    if (media) {
      setImage(
        `${process.env.REACT_APP_BASE_URL}/images/w300/backdrop_path/${media.backdrop_path}`
      );
    }
  }, [media]);

  return (
    <div>
      {media ? (
        <div className="max-w-72 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 m-3">
          {show_image && (
            <Link to={`/media/${media.media_type_id}/${media.media_id}`}>
              <img className="rounded-t-lg" src={image} alt="" />
            </Link>
          )}
          <div className="p-2">
            <Link to={`/media/${media.media_type_id}/${media.media_id}`}>
              <h5 className="mb-1 mt-1 text-2m font-bold tracking-tight text-gray-900 dark:text-white">
                {media.name}
              </h5>
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
