import { MicroButton } from "./MicroButton";

export const Pagination = ({
  changePageNo,
  data,
  dataPerPage,
  currentPage,
}) => {
  let numbers = [];
  for (
    let i = Math.max(1, currentPage - 2);
    i <=
    Math.min(Math.ceil(data / dataPerPage), Math.max(1, currentPage - 2) + 4);
    i++
  ) {
    numbers.push(i);
  }
  if (!data) {
    return <></>;
  }

  return (
    <div>
      <MicroButton
        key={-1}
        onClick={() => {
          changePageNo(1);
        }}
      >
        {"<<"}
      </MicroButton>
      <MicroButton
        key={0}
        onClick={() => {
          changePageNo(currentPage - 1);
        }}
        buttonEnabled={currentPage !== 1}
      >
        {"<"}
      </MicroButton>
      {numbers &&
        numbers.map((no) => {
          return (
            <MicroButton
              key={no}
              onClick={() => {
                changePageNo(no);
              }}
            >
              {no}
            </MicroButton>
          );
        })}
      <MicroButton
        key={998}
        onClick={() => {
          changePageNo(currentPage + 1);
        }}
        buttonEnabled={currentPage !== Math.ceil(data / dataPerPage)}
      >
        {">"}
      </MicroButton>
      <MicroButton
        key={999}
        onClick={() => {
          changePageNo(Math.ceil(data / dataPerPage));
        }}
      >
        {">>"}
      </MicroButton>
    </div>
  );
};
