export const Button = ({ children, onClick, range }) => {
  const colorRange = range ? range : "from-blue-500 via-blue-600 to-blue-700";

  const clName =
    "w-20 text-md text-white bg-gradient-to-r " +
    colorRange +
    "rounded-lg px-2 py-2 mr-2 mb-2 font-medium";

  return (
    <button onClick={onClick} className={clName}>
      {children}
    </button>
  );
};
