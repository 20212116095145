import axios from "axios";
import { useEffect, useState } from "react";
import { MiniButton, Pagination } from ".";

export const Slots = ({
  user,
  media_type_id,
  media_id,
  editSlot,
  defaultSlot,
  location_id,
  parentSetSeed,
}) => {
  const [slots, setSlots] = useState();
  const [seed, setSeed] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [dataPerPage, setDataPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const url = process.env.REACT_APP_API_URL + `slots/${location_id}`;

  useEffect(() => {
    setCurrentPage(Math.ceil(defaultSlot / dataPerPage) || 1);
  }, [dataPerPage, defaultSlot, seed]);

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setSlots(data);
    }
    if (location_id !== -1) {
      fetchResults();
    }
  }, [url, user.accessToken, location_id, defaultSlot, seed]);

  function onClick(media_type_id, media_id, slot) {
    const location = {
      location_id: slot.location_id,
      slot: slot.slot,
      media_type_id: media_type_id,
      media_id: media_id,
    };
    async function updateSlot() {
      if (!slot.media_id) {
        const url2 = process.env.REACT_APP_API_URL + "slot";
        await axios.post(url2, location, {
          headers: { authorization: "Bearer " + user.accessToken },
        });
        parentSetSeed(Math.random());
        setSeed(Math.random());
        //setCurrentPage(Math.ceil(slot.slot / dataPerPage));
      } else {
        const url3 =
          process.env.REACT_APP_API_URL +
          "slot/delete/" +
          slot.location_id +
          "/" +
          slot.slot;
        await axios.put(url3, [], {
          headers: { authorization: "Bearer " + user.accessToken },
        });
        parentSetSeed(Math.random());
        setSeed(Math.random());
      }
    }
    updateSlot();
  }

  const changePageNo = (number) => setCurrentPage(number);
  const lastIndex = currentPage * dataPerPage;
  const firstIndex = lastIndex - dataPerPage;
  const currentData = slots?.slice(firstIndex, lastIndex);

  return (
    <div className="mt-5">
      {currentData && (
        <div className="grid grid-cols-6 grid-flow-dense">
          {currentData.map((slot) => (
            <MiniButton
              key={slot.slot}
              user={user}
              buttonEnabled={
                editSlot &&
                (!slot.media_id ||
                  parseInt(slot.media_id) === parseInt(media_id))
              }
              onClick={() => onClick(media_type_id, media_id, slot)}
              taken={slot.media_id}
              parent={
                parseInt(slot.parent_media_type_id) ===
                  parseInt(media_type_id) &&
                parseInt(slot.parent_media_id) === parseInt(media_id)
              }
              matched={
                parseInt(slot.media_type_id) === parseInt(media_type_id) &&
                parseInt(slot.media_id) === parseInt(media_id)
              }
            >
              {slot.slot}
            </MiniButton>
          ))}
        </div>
      )}
      <Pagination
        changePageNo={changePageNo}
        data={slots?.length}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
      />
    </div>
  );
};
