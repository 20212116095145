import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { SideMediaList } from "../components";
import { useTitle } from "../hooks";

export const PersonDetail = ({ user }) => {
  const params = useParams();
  const [person, setPerson] = useState();
  const [image, setImage] = useState();

  useTitle("Person");

  const url =
    process.env.REACT_APP_API_URL + `person/details/${params.person_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setPerson(data[0]);
      setImage(
        `${process.env.REACT_APP_BASE_URL}/images/w400/profile_path/${data[0].image_location}`
      );
    }
    fetchResults();
  }, [url, user.accessToken]);

  return (
    <main>
      {person && (
        <section className="flex flex-grow justify-around flex-wrap py-5">
          <div className="max-w-sm">
            <img className="rounded" src={image} alt={person.name} />
          </div>
          <div className="sm:w-full md:w-1/2 text-gray-700 text-lg dark:text-white">
            <h1 className="text-4xl font-bold my-3 text-center lg:text-left">
              <Link to={`/person/${person.person_id}`}>{person.name}</Link>
            </h1>
            <p className="my-4">{person.biography}</p>
          </div>
          <div className="text-gray-700 text-lg dark:text-white">
            <SideMediaList user={user} person_id={person.person_id} />
          </div>
        </section>
      )}
    </main>
  );
};
