export const MicroButton = ({
  children,
  onClick,
  range,
  buttonEnabled = true,
}) => {
  const clName =
    "h-6 w-6 text-sm text-justify-center align-middle rounded-sm font-small " +
    "bg-transparent border border-gray-400 " +
    "text-gray-700 shadow-md" +
    "dark:border-gray-600 dark:text-gray-300 " +
    "hover:bg-gray-800 dark:hover:text-white";

  return (
    <button onClick={onClick} className={clName} disabled={!buttonEnabled}>
      {children}
    </button>
  );
};
