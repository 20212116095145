import { Link } from "react-router-dom";
import { Genres, MediaLocations, AddMedia } from ".";

export const Card = ({ user, media, useTMDB, mediaType }) => {
  var first_shown_date = media.first_shown_date
    ? media.first_shown_date.substring(0, 4)
    : media.release_date
    ? media.release_date.substring(0, 4)
    : media.first_air_date && media.first_air_date.substring(0, 4);

  const image = useTMDB
    ? `https://image.tmdb.org/t/p/w500/${media.backdrop_path}`
    : `${process.env.REACT_APP_BASE_URL}/images/w500/backdrop_path/${media.backdrop_path}`;

  return (
    <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 m-3">
      <Link
        to={media.media_id && `/media/${media.media_type_id}/${media.media_id}`}
      >
        <img className="rounded-t-lg" src={image} alt="" />
      </Link>
      <div className="p-5">
        <Link
          to={
            media.media_id && `/media/${media.media_type_id}/${media.media_id}`
          }
        >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {media.name || media.title}{" "}
            {first_shown_date && "(" + first_shown_date + ")"}
          </h5>
        </Link>
        {!useTMDB && (
          <Genres
            user={user}
            key={media.media_id}
            media_type_id={media.media_type_id}
            media_id={media.media_id}
          />
        )}

        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {media.overview}
        </p>
      </div>
      <div className="sticky top-[100vh] max-w-sm bg-white rounded-b-lg border border-gray-200 shadow-md dark:bg-gray-700 dark:border-gray-600">
        <div className="mb-3 mx-5 font-normal text-gray-700 dark:text-gray-400">
          {media.media_id ? (
            <MediaLocations
              user={user}
              key={media.media_id}
              media_type_id={media.media_type_id}
              media_id={media.media_id}
            />
          ) : (
            <AddMedia user={user} mediaType={mediaType} tmdbId={media.id} />
          )}
        </div>
      </div>
    </div>
  );
};
