import { Link } from "react-router-dom";

export const CatalogListItem = ({ user, media }) => {
  const image = `${process.env.REACT_APP_BASE_URL}/images/w200/poster_path/${media.poster_path}`;
  return (
    <tr className="even:bg-gray-200  odd:bg-gray-300">
      <td>{media.slot}</td>
      <td>
        <Link
          to={
            media.media_id && `/media/${media.media_type_id}/${media.media_id}`
          }
        >
          <img className="rounded-t-lg" width={50} src={image} alt="" />
        </Link>
      </td>
      <td>
        {media.name}
        {media.year && "(" & media.year & ")"}
      </td>
    </tr>
  );
};
