import axios from "axios";
import { useState, useEffect } from "react";
import { MiniCard } from ".";

export const Children = ({ user, media_type_id, media_id, show_image }) => {
  const [children, setChildren] = useState();

  const url =
    process.env.REACT_APP_API_URL +
    `media/parent/list?mediaTypeId=${media_type_id}&mediaId=${media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setChildren(data);
    }
    fetchResults();
  }, [url, user.accessToken]);

  return (
    <>
      {children ? (
        <div className="my-7 flex flex-col gap-2">
          {children.map((child) => (
            <MiniCard
              key={child.media_id}
              user={user}
              media_type_id={child.media_type_id}
              media_id={child.media_id}
              show_image={show_image}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
