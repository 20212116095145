import { useEffect, useState } from "react";
import { MiniButton, CatalogList } from "../components";
import axios from "axios";

export const Catalog = ({ user, apiPath, mediaTypeId, mediaListSeed }) => {
  const [catalogListing, setCatalogListing] = useState();
  const [location, setLocation] = useState();
  const [mediaList, setMediaList] = useState();

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + `locations`;

    async function fetchResults() {
      const response = await axios.get(url, {
        headers: {
          authorization: "Bearer " + user.accessToken,
        },
      });
      const data = await response.data;
      setCatalogListing(data);
    }
    fetchResults();
  }, [user.accessToken]);

  useEffect(() => {
    if (mediaTypeId && location) {
      const queryTerm = `?locationId=${location}&mediaTypeId=${mediaTypeId}`;
      const url = process.env.REACT_APP_API_URL + `${apiPath}${queryTerm}`;

      async function fetchResults() {
        const response = await axios.get(url, {
          headers: {
            authorization: "Bearer " + user.accessToken,
          },
        });
        const data = await response.data;
        setMediaList(data);
      }
      fetchResults();
    }
  }, [
    user.accessToken,
    apiPath,
    mediaTypeId,
    mediaListSeed,
    catalogListing,
    location,
  ]);

  return (
    <main>
      <section className="max-w-7xl mx-auto py-7">
        {catalogListing && (
          <div className="my-4 flex flex-wrap gap-2">
            {catalogListing.map((catalog) => (
              <MiniButton
                key={catalog.location_id}
                width={2}
                buttonEnabled={true}
                onClick={() => setLocation(catalog.location_id)}
              >
                {catalog.location}
              </MiniButton>
            ))}
          </div>
        )}
        <CatalogList user={user} mediaList={mediaList} dataPerPage={16} />
      </section>
    </main>
  );
};
