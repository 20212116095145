import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Header, Footer } from "./components";
import { AllRoutes } from "./routes/AllRoutes";
import AuthService from "./adapters/Auth.Service";
import { useTitle } from "./hooks";

function App() {
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState(null);
  const [mediaTypeId, setMediaTypeId] = useState(-1);
  const [mediaListSeed, setMediaListSeed] = useState(1);

  useTitle("Home");

  useEffect(() => {
    setUser(AuthService.getCurrentUser());
    setMediaTypeId(JSON.parse(localStorage.getItem("mediaTypeId")) || 1);
  }, []);

  function logOut() {
    AuthService.logout();
    navigate("/login");
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Header
        user={user}
        apiURL={apiURL}
        mediaTypeId={mediaTypeId}
        setMediaTypeId={setMediaTypeId}
        setMediaListSeed={setMediaListSeed}
      />
      <AllRoutes
        user={user}
        apiURL={apiURL}
        setUser={setUser}
        mediaTypeId={mediaTypeId}
        setMediaTypeId={setMediaTypeId}
        mediaListSeed={mediaListSeed}
      />
      <Footer user={user} logOut={logOut} />
    </div>
  );
}

export default App;
