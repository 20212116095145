import axios from "axios";
import { useEffect, useState } from "react";

export const Category = ({
  user,
  media_type_id,
  media_id,
  category_id,
  parentSetSeed,
}) => {
  const [isChecked, setIsChecked] = useState();
  const [seed, setSeed] = useState(1);
  const [category, setCategory] = useState();

  const url =
    process.env.REACT_APP_API_URL +
    `media/category/${media_type_id}/${media_id}/${category_id}`;

  function postData() {
    axios.post(url, [], {
      headers: { authorization: "Bearer " + user.accessToken },
    });
  }

  function deleteData() {
    axios.put(url, [], {
      headers: { authorization: "Bearer " + user.accessToken },
    });
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      postData();
    } else {
      deleteData();
    }
    setIsChecked(!isChecked);
    setSeed(Math.random());
    parentSetSeed(Math.random);
  };

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setCategory(data[0]);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  return (
    <div className="flex items-center mb-4">
      <input
        id="default-checkbox"
        type="checkbox"
        value=""
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 checked"
        defaultChecked={category?.value}
        checked={category?.value}
        onChange={handleChange}
        disabled={!user.isAdmin}
      />
      <label
        for="default-checkbox"
        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {category?.category}
      </label>
    </div>
  );
};
