import { Link } from "react-router-dom";

export const Footer = ({ user, logOut }) => {
  return (
    <footer className="sticky top-[100vh] p-1 max-h-[40px] bg-white shadow flex items-center md:justify-between md:p-6 dark:bg-gray-800">
      <span className="hidden md:block text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2024{" "}
        <Link to="/" className="hover:underline">
          MediaCatalog
        </Link>
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400"></ul>
      {user && (
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          Logged in as:{" "}
          <button onClick={() => logOut()}>{user?.username}</button>
        </span>
      )}
    </footer>
  );
};
