import { useState, useEffect } from "react";
import axios from "axios";
import { Locations, Slots, MiniButton } from ".";

export const MediaDetailsLocation = ({ user, media_id, media_type_id }) => {
  const [mediaLocations, setMediaLocations] = useState();
  const [locationId, setLocationId] = useState();
  const [editSlot, setEditSlot] = useState();
  const [firstUsedSlot, setFirstUsedSlot] = useState();
  const [firstEmptySlot, setFirstEmptySlot] = useState(-1);
  const [defaultSlot, setDefaultSlot] = useState(999);
  const [seed, setSeed] = useState(1);

  const url =
    process.env.REACT_APP_API_URL +
    `media/locations/${media_type_id}/${media_id}`;
  const url2 = process.env.REACT_APP_API_URL + `slot/empty/${locationId}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      setLocationId(-1);
      const data = await response.data;
      setMediaLocations(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  useEffect(() => {
    async function fetchResults2() {
      const response2 = await axios.get(url2, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data2 = await response2.data;
      //setDefaultSlot(data2[0].firstslot);
      setFirstEmptySlot(data2[0].firstslot);
    }
    if (locationId) {
      fetchResults2();
    }
  }, [user.accessToken, url2, seed, locationId]);

  useEffect(() => {
    if (mediaLocations?.length > 0) {
      setLocationId(mediaLocations[mediaLocations.length - 1].location_id);
      //setDefaultSlot(mediaLocations[mediaLocations.length - 1].slot);
      setFirstUsedSlot(mediaLocations[mediaLocations.length - 1].slot);
    }
  }, [mediaLocations]);

  useEffect(() => {
    setDefaultSlot(Math.min(firstEmptySlot, firstUsedSlot));
  }, [firstEmptySlot, firstUsedSlot]);

  return (
    <span className="flex flex-row">
      <Locations
        user={user}
        locationId={locationId}
        setLocationId={setLocationId}
        editSlot={editSlot}
        parentSetSeed={setSeed}
      />
      {locationId && locationId !== -1 && (
        <Slots
          className="justify-left"
          user={user}
          location_id={locationId}
          media_type_id={media_type_id}
          media_id={media_id}
          editSlot={editSlot}
          defaultSlot={defaultSlot}
          parentSetSeed={setSeed}
        />
      )}
      <div className="my-5 ml-4">
        <MiniButton
          buttonEnabled={user.isAdmin}
          onClick={() => setEditSlot(!editSlot)}
          matched={editSlot}
        >
          {editSlot ? "Save" : "Edit"}
        </MiniButton>
      </div>
    </span>
  );
};
