import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthAPI from "../adapters/Auth.Service";

export const useProtectedFetchRecord = (
  user,
  apiURL,
  apiPath,
  queryTerm = ""
) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const url = apiURL + apiPath + queryTerm;

  useEffect(() => {
    async function fetchResults() {
      function onError(error) {
        AuthAPI.logout();
        navigate("/login");
      }
      function onSuccess(response) {
        return response;
      }
      if (url && user) {
        const response = await axios
          .get(url, {
            headers: { authorization: "Bearer " + user.accessToken },
          })
          .then(onSuccess)
          .catch(onError);
        const data = await response.data;
        setData(data[0]);
      }
    }
    fetchResults();
  }, [url, navigate, user, queryTerm]);

  return { data };
};
