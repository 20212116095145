import axios from "axios";
import { useEffect, useState } from "react";

export const MediaLocations = ({ user, media_type_id, media_id }) => {
  const [locations, setLocations] = useState();

  const url =
    process.env.REACT_APP_API_URL +
    `media/locationsaggr/${media_type_id}/${media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setLocations(data);
    }
    if (media_id) {
      fetchResults();
    }
  }, [url, user.accessToken, media_id]);

  return (
    <div>
      {locations && (
        <div className="my-4 min-h-6 flex flex-wrap gap-2">
          {locations.map((location) => (
            <span
              className="mr-2 text-gray-700 dark:text-white"
              key={locations.location_id}
            >
              {location.location + "(" + location.slots + ")"}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
