import { useEffect, useState } from "react";
import { MediaGrid } from "../components";
import axios from "axios";
import { useTitle } from "../hooks";

export const MediaList = ({
  user,
  apiPath,
  queryTerm = "",
  mediaTypeId = 1,
  mediaListSeed,
  title,
}) => {
  const [mediaList, setMediaList] = useState();
  useTitle(title);

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + `${apiPath}${queryTerm}`;

    async function fetchResults() {
      const response = await axios.get(url, {
        headers: {
          authorization: "Bearer " + user.accessToken,
        },
      });
      const data = await response.data;
      setMediaList(data);
    }
    fetchResults();
  }, [user, apiPath, mediaTypeId, queryTerm, mediaListSeed]);

  return (
    <main>
      <section className="max-w-7xl mx-auto py-7">
        <MediaGrid user={user} mediaList={mediaList} dataPerPage={6} />
      </section>
    </main>
  );
};
