import React, { useState, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import axios from "axios";

export const UserRating = ({ user, media_type_id, media_id }) => {
  const [rating, setRating] = useState(0);
  const [hasDBValue, setHasDBValue] = useState(false);

  const url =
    process.env.REACT_APP_API_URL +
    `media/rating/${media_type_id}/${media_id}/${user.id}`;

  function handleRating(rate) {
    setRating(rate);

    async function updateDB() {
      const dbRating = {
        media_type_id: media_type_id,
        media_id: media_id,
        user_id: user.id,
        rating: rate,
      };

      const url2 = process.env.REACT_APP_API_URL + "media/rating";
      if (!hasDBValue) {
        await axios.post(url2, dbRating, {
          headers: { authorization: "Bearer " + user.accessToken },
        });
      } else {
        await axios.put(url2, dbRating, {
          headers: { authorization: "Bearer " + user.accessToken },
        });
      }
      setHasDBValue(true);
    }
    updateDB();
  }

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      if (data[0].rating != null) {
        setHasDBValue(true);
      }
      setRating(data[0].rating || 0);
    }
    if (media_id) {
      fetchResults();
    }
  }, [url, user.accessToken, media_id]);

  return (
    <div className="flex flex-row">
      <Rating
        onClick={handleRating}
        initialValue={rating.rating}
        transition={true}
        allowFraction={true}
        size={20}
        label
        iconsCount={10}
        SVGclassName="inline-block"
      />
      <div className="mt-1 ml-4">{hasDBValue && rating}</div>
    </div>
  );
};
