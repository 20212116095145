import axios from "axios";
import { useEffect, useState } from "react";
import { CastItem } from "./CastItem";

export const CastList = ({ user, media_id, media_type_id }) => {
  const [castList, setCastList] = useState();
  const [seed, setSeed] = useState(1);

  const url =
    process.env.REACT_APP_API_URL + `media/cast/${media_type_id}/${media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setCastList(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  return (
    <div className="flex flex-nowrap max-w-full sm:max-h-full xl:flex-col w-screen xl:w-48 xl:h-[calc(100vh-70px)] overscroll-contain overflow-auto snap-center scroll-smooth xl:scroll-auto">
      {castList &&
        castList.map((cast) => (
          <CastItem user={user} key={cast.person_id} cast={cast} />
        ))}
    </div>
  );
};
