import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  CastList,
  Genres,
  Categories,
  Children,
  MiniCard,
  MediaDetailsLocation,
  UserRating,
} from "../components";
import { useTitle } from "../hooks";

export const MediaDetail = ({ user }) => {
  const params = useParams();
  const [image, setImage] = useState();
  const [media, setMedia] = useState();

  useTitle("Details");

  const url =
    process.env.REACT_APP_API_URL +
    `media/details/${params.media_type_id}/${params.media_id}`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setMedia(data[0]);
    }
    fetchResults();
  }, [url, setMedia, user.accessToken]);

  useEffect(() => {
    async function fetchImage() {
      if (media) {
        if (media.poster_path) {
          setImage(
            `${process.env.REACT_APP_BASE_URL}/images/w400/poster_path/${media.poster_path}`
          );
        } else {
          setImage(
            `${process.env.REACT_APP_BASE_URL}/images/w400/backdrop_path/${media.backdrop_path}`
          );
        }
      }
    }
    fetchImage();
  }, [media, user.accessToken]);

  return (
    <main>
      {media && (
        <section className="flex flex-grow justify-around flex-wrap py-5">
          <div className="max-w-sm">
            <img className="rounded" src={image} alt={media.name} />
          </div>
          <div className="sm:w-full md:w-1/2 text-gray-700 text-lg dark:text-white">
            <h1 className="text-4xl font-bold my-3 text-center lg:text-left">
              <Link to={`/media/${media.media_type_id}/${media.media_id}`}>
                {media.name}
              </Link>
            </h1>
            <p className="my-4">{media.overview}</p>
            <Genres
              user={user}
              media_type_id={params.media_type_id}
              media_id={params.media_id}
            />
            <div className="flex flex-wrap flex-row">
              <div className="flex-col flex-grow justify-left">
                <div className="flex flex-col flex-grow">
                  <div className="flex-1 flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-yellow-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Rating star</title>
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <p className="ml-2 text-gray-900 dark:text-white">
                      {media.vote_average}
                    </p>
                    <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
                    <span className="text-gray-900 dark:text-white">
                      {media.vote_count} reviews
                    </span>
                  </div>
                  <UserRating
                    user={user}
                    media_type_id={media.media_type_id}
                    media_id={media.media_id}
                    className="flex flex-col"
                  />
                  <p className="my-1">
                    <span className="mr-2 font-bold">Runtime:</span>
                    <span>{media.runtime} min.</span>
                  </p>
                  <p className="my-1">
                    <span className="mr-2 font-bold">Certificate:</span>
                    <span>{media.certificate}</span>
                  </p>
                  <p className="my-1">
                    <span className="mr-2 font-bold">Released:</span>
                    <span>{media.first_shown_date?.substr(0, 4) || "N/A"}</span>
                  </p>
                  <p className="my-1">
                    <span className="mr-2 font-bold">IMDB Code:</span>
                    <a
                      href={`https://www.imdb.com/title/${media.imdb_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {media.imdb_id || "N/A"}
                    </a>
                  </p>
                  <Categories
                    className="my-1"
                    user={user}
                    media_type_id={params.media_type_id}
                    media_id={params.media_id}
                  />
                  <span className="flex flex-row">
                    {media.media_type !== "Episode" && (
                      <MediaDetailsLocation
                        user={user}
                        media_type_id={params.media_type_id}
                        media_id={params.media_id}
                      />
                    )}
                    <div className="my-5 ml-4"></div>
                  </span>
                </div>
              </div>
              <div className="flex-col justify-right">
                {media.parent_media_id && (
                  <MiniCard
                    user={user}
                    media_type_id={media.parent_media_type_id}
                    media_id={media.parent_media_id}
                    show_image={true}
                  />
                )}
                <Children
                  user={user}
                  media_type_id={media.media_type_id}
                  media_id={media.media_id}
                  show_image={false}
                />
              </div>
            </div>
          </div>
          <div className="text-gray-700 text-lg dark:text-white">
            <CastList
              user={user}
              media_type_id={media.media_type_id}
              media_id={media.media_id}
            />
          </div>
        </section>
      )}
    </main>
  );
};
