import { useEffect, useState } from "react";
import { CatalogListItem, Pagination } from "../components";

export const CatalogList = ({ user, mediaList, dataPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const changePageNo = (number) => setCurrentPage(number);
  const lastIndex = currentPage * dataPerPage;
  const firstIndex = lastIndex - dataPerPage;
  const currentData = mediaList?.slice(firstIndex, lastIndex);

  useEffect(() => {
    setCurrentPage(1);
  }, [mediaList]);

  return (
    <main>
      {mediaList && (
        <section className="max-w-7xl mx-auto py-7">
          <table className="table w-full text-lg">
            <thead className="bg-gray-400">
              <tr>
                <th className="w-1/8 align-left">Pos.</th>
                <th className="w-1/8">Img.</th>
                <th className="w-3/4">Title</th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((media) => (
                  <CatalogListItem
                    key={media.media_id || media.id}
                    user={user}
                    media={media}
                  />
                ))}
            </tbody>
          </table>
          <div className="flex w-full-screen justify-center">
            <Pagination
              changePageNo={changePageNo}
              data={mediaList?.length}
              dataPerPage={dataPerPage}
              currentPage={currentPage}
            />
          </div>
        </section>
      )}
    </main>
  );
};
