import { Link } from "react-router-dom";

export const SideMediaItem = ({ user, media }) => {
  const image = `${process.env.REACT_APP_BASE_URL}/images/w200/poster_path/${media.poster_path}`;

  return (
    <div className="snap-center min-w-40 max-w-48 p-2">
      <Link to={`/media/${media.media_type_id}/${media.media_id}`}>
        <img className="rounded-t-lg" src={image} alt="" />
        <p>{media.name}</p>
        <p className="text-sm">
          <i>
            {media.character_name}
            {media.suffix && " (" + media.suffix + ")"}
          </i>
        </p>
      </Link>
    </div>
  );
};
