import axios from "axios";
import { MiniButton } from ".";

export const AddMedia = ({ user, mediaType, tmdbId }) => {
  function addMedia(format) {
    const url =
      process.env.REACT_APP_API_URL +
      `media/add/${mediaType}/${format}/${tmdbId}`;
    axios.post(url, [], {
      headers: { authorization: "Bearer " + user.accessToken },
    });
  }
  return (
    <div>
      <div className="my-4 min-h-6 flex flex-wrap gap-2">
        <span className="flex flex-row mr-2 text-gray-700 dark:text-white">
          <MiniButton buttonEnabled={true} onClick={() => addMedia("DVD")}>
            Add DVD
          </MiniButton>
          <MiniButton buttonEnabled={true} onClick={() => addMedia("Bluray")}>
            Add Bluray
          </MiniButton>
        </span>
      </div>
    </div>
  );
};
