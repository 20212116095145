import { Link } from "react-router-dom";

export const CastItem = ({ user, cast }) => {
  const image = `${process.env.REACT_APP_BASE_URL}/images/w200/profile_path/${cast.profile_path}`;

  return (
    <div className="snap-center min-w-40 max-w-48 p-2">
      <Link to={cast.person_id && `/person/${cast.person_id}`}>
        <img className="rounded-t-lg" src={image} alt="" />
        <p>{cast.person_name}</p>
        <p className="text-sm">
          <i>{cast.character_name}</i>
        </p>
      </Link>
    </div>
  );
};
