import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "../components";
import { Checkbox } from "../components/Checkbox";

export const AdminUsers = ({ user }) => {
  const [seed, setSeed] = useState(1);
  const [users, setUsers] = useState();
  const [addUser, setAddUser] = useState(false);
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [usr, setUsr] = useState(true);
  const [admin, setAdmin] = useState();

  const url = process.env.REACT_APP_API_URL + `users`;
  const userUrl = process.env.REACT_APP_API_URL + `user`;

  useEffect(() => {
    async function fetchResults() {
      const response = await axios.get(url, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      const data = await response.data;
      setUsers(data);
    }
    fetchResults();
  }, [url, user.accessToken, seed]);

  const handleAddLocation = (event) => {
    if (!addUser) {
      setAddUser(!addUser);
    } else {
      const profile = {
        username: userName,
        email: email,
        password: password,
        usr: usr,
        admin: admin,
      };
      axios.post(userUrl, profile, {
        headers: { authorization: "Bearer " + user.accessToken },
      });
      setAddUser(!addUser);
      setSeed(Math.random());
    }
  };

  const onChangeUser = (e) => {
    const data = e.target.value;
    setUserName(data);
  };

  const onChangeEmail = (e) => {
    const data = e.target.value;
    setEmail(data);
  };

  const onChangePassword = (e) => {
    const data = e.target.value;
    setPassword(data);
  };

  return (
    <main>
      <section className="flex flex-grow justify-around flex-wrap py-5">
        <div className="flex flex-col">
          <table className="table-auto shadow-sm overflow-hidden my-8">
            <thead>
              <tr>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  User
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Access
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                  Admin
                </th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left" />
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
              {users &&
                users.map((user) => (
                  <tr key={user.id}>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {user.username}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {user.usr}
                    </td>
                    <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                      {user.admin}
                    </td>
                    <td>
                      <Button>Update</Button>
                      <Button range="from-red-500 via-red-600 to-red-700">
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Button onClick={() => handleAddLocation()}>
            {addUser ? "Submit" : "Add"}
          </Button>
          {addUser && (
            <div className="tp-3 text-slate-500 dark:text-slate-400">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline"
                id="user"
                type="text"
                placeholder="User"
                onChange={onChangeUser}
              />
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Email"
                onChange={onChangeEmail}
              />
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline"
                id="password"
                type="text"
                placeholder="Password"
                onChange={onChangePassword}
              />
              <div className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline">
                <Checkbox
                  user={user}
                  isChecked={usr}
                  setIsChecked={setUsr}
                  value={"User"}
                />
              </div>
              <div className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight bg-transparent focus:outline-none focus:shadow-outline">
                <Checkbox
                  user={user}
                  isChecked={admin}
                  setIsChecked={setAdmin}
                  value={"Admin"}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};
