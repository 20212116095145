import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MiniButton, MediaGrid } from "../components";
import { useProtectedFetchRecord, useTitle } from "../hooks";

export const SearchResults = ({ user, title, mediaTypeId, queryTerm = "" }) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [mediaList, setMediaList] = useState();
  const [useTMDB, setUseTMDB] = useState(false);

  useTitle(title);

  const apiObject = useProtectedFetchRecord(
    user,
    process.env.REACT_APP_API_URL,
    "param/tmdb_api_key",
    ""
  );

  const mediaType = useProtectedFetchRecord(
    user,
    process.env.REACT_APP_API_URL,
    "media/typeById/" + mediaTypeId,
    ""
  ).data.media_type;

  const apiKey = apiObject.data.param_value;

  var url = "";
  if (!useTMDB) {
    url =
      url +
      process.env.REACT_APP_API_URL +
      "media/search?q=" +
      params.searchString +
      `&mediaTypeId=${mediaTypeId}` +
      queryTerm;
  } else {
    url =
      url +
      `https://api.themoviedb.org/3/search/${mediaType.toLowerCase()}?api_key=${apiKey}&query=${
        params.searchString
      }`;
  }

  useEffect(() => {
    async function fetchResults() {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          authorization: "Bearer " + (!useTMDB && user.accessToken),
        },
      });
      const data = !useTMDB ? await response.data : response.data.results;
      setMediaList(data);
      setLoading(false);
    }
    fetchResults();
  }, [user, url, useTMDB, mediaTypeId, params.searchString, queryTerm, apiKey]);

  if (loading) {
    return <h1>Loading...</h1>;
  }
  return (
    <main>
      <section className="max-w-7xl mx-auto py-7">
        <div className="flex justify-end">
          <MiniButton
            buttonEnabled={true}
            onClick={() => setUseTMDB(!useTMDB)}
            width={2}
          >
            Use {useTMDB ? " Local" : " TMDB"}
          </MiniButton>
        </div>
        {mediaList && (
          <MediaGrid
            user={user}
            mediaList={mediaList}
            useTMDB={useTMDB}
            mediaType={mediaType}
            dataPerPage={9}
          />
        )}
      </section>
    </main>
  );
};
