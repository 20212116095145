export const MiniButton = ({
  children,
  buttonEnabled,
  onClick,
  taken,
  matched,
  parent,
  width = 1,
}) => {
  const buttonWidth = width * 9;

  const matchedStyle =
    " border-5 border-yellow-400 font-bold dark:border-yellow-600 dark:hover:bg-yellow-700 bg-yellow-500 text-white dark:text-gray-950  dark:hover:text-white ";

  const usedStyle =
    " border-5 border-orange-400 font-bold dark:border-slate-600 dark:hover:bg-slate-700 bg-blue-400 text-white dark:bg-slate-300 dark:text-gray-950  dark:hover:text-white ";

  const parentStyle =
    " border-10 border-orange-300 font-bold dark:border-slate-300 dark:hover:bg-slate-600 bg-blue-300 text-white dark:bg-slate-100 dark:text-gray-950  dark:hover:text-white ";

  const normalStyle =
    " border border-gray-200 dark:border-gray-600 dark:hover:bg-gray-700 dark:bg-gray-800 text-gray-700 dark:text-gray-400  dark:hover:text-white ";

  var clName =
    `w-${buttonWidth} h-9 p-1 text-s ` +
    "flex justify-center items-center p-2 text-xs font-large  bg-white rounded-lg " +
    "hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 focus:outline-none ";

  if (matched) {
    clName += matchedStyle;
  } else if (parent) {
    clName += parentStyle;
  } else if (taken) {
    clName += usedStyle;
  } else {
    clName += normalStyle;
  }

  return (
    <button
      onClick={onClick}
      data-tooltip-target="navbar-search-example-toggle-dark-mode-tooltip"
      type="button"
      className={clName}
      disabled={!buttonEnabled}
    >
      {children}
    </button>
  );
};
